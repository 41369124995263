body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  /*color:#184e72e6;*/
  text-decoration: none;
 /* color:#255607;*/
 color:#1f64a0
}
/*
for general textfields
*/
.MuiOutlinedInput-input {
  font-size: .8rem;
}
/*for select labels*/
.MuiInputLabel-root{
  font-size: .8rem;
}
/*for selected label */
.MuiOutlinedInput-root{
  font-size: .8rem;
}
/*for selected options*/
.MuiMenuItem-root{
  font-size: .8rem;
}

.ql-container{
  font-family: "Roboto Slab" !important;
}
.ql-editor {
  min-height: 100px;
  max-height: 60vh;
}
.editorHtml p{
  padding:0;
  font-size: .9rem;
  color:#333;
}



.formHeader{
  padding:.5rem;
  background-color:#a9bdc7;
  color:#222;
}

div#root{
  background-color: #e6e5e1;/*accent2.main*/
}
.editorHtmlSpan p{
  margin: 0;
}
.cust-bullet{
  /* width: 0; */
  /* height: 0; */
  border-bottom: 10px solid red;
  border-right: 10px solid transparent;
}


/* latin */
@font-face {
  font-family: 'Permanent Marker';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/permanentmarker/v16/Fh4uPib9Iyv2ucM6pGQMWimMp004La2Cf5b6jlg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* latin-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoslab/v23/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISmYWRlV9Su1caiTVo.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoslab/v23/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISmb2RlV9Su1cai.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
